import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import moment, { Moment } from 'moment';
import {
  transferReservationBaseFormBuilder,
  TransferReservationBaseFormType,
  transferReservationBaseLocationFormBuilder,
  TransferReservationBaseLocationFormType
} from '../../viajeTraslado/forms/base/TransferReservationBaseFormTypes';
import {
  ReservaTrasladoRepetitiveCreateModel,
  TransferReservationRepetitiveModel
} from '../models/TransferreservationRepetitiveModel';

export interface TransferReservationRepetitiveFormType
  extends TransferReservationBaseFormType {
  fechaYHoraDeLlegada: Moment;
  minutosPreviosASalida: Moment;
  puntos: TransferReservationBaseLocationFormType[];
  repeatOnMonday: boolean;
  repeatOnTuesday: boolean;
  repeatOnWednesday: boolean;
  repeatOnThursday: boolean;
  repeatOnFriday: boolean;
  repeatOnSaturday: boolean;
  repeatOnSunday: boolean;
  repetitionFinishDate: Moment;
  repetitionInitDate: Moment;
  isEvent: boolean;
  nameEvent: string;
  customerPrice: number;
  providerPrice: number;
  isLLTPais: boolean;
}

function reservaTrasladoRepetitiveFormToModel(
  form: TransferReservationRepetitiveFormType
): ReservaTrasladoRepetitiveCreateModel {
  return {
    ...transferReservationBaseFormBuilder.toModel(form),
    fechaYHoraDeLlegada: form.fechaYHoraDeLlegada,
    fechaYHoraDeSalida: form.minutosPreviosASalida,
    puntos: form.puntos.map(transferReservationBaseLocationFormBuilder.toModel),
    repeatOnMonday: form.repeatOnMonday,
    repeatOnTuesday: form.repeatOnTuesday,
    repeatOnWednesday: form.repeatOnWednesday,
    repeatOnThursday: form.repeatOnThursday,
    repeatOnFriday: form.repeatOnFriday,
    repeatOnSaturday: form.repeatOnSaturday,
    repeatOnSunday: form.repeatOnSunday,
    repetitionFinishDate: form.repetitionFinishDate,
    repetitionInitDate: form.repetitionInitDate
  };
}

function reservaRepetitiveModelToForm(
  reserva: TransferReservationRepetitiveModel,
  cliente: ClientBusinessModel,
  fixedRate: RateModel['categories'][0]['fixedPrices'][0] | null
): TransferReservationRepetitiveFormType {
  return {
    cliente,
    solicitante: reserva.requirements.applicant!,
    autorizado: reserva.requirements.authorized ?? null,
    costCenter: reserva.requirements.costCenter ?? '',
    subCostCenter: reserva.requirements.subCostCenter ?? '',
    sector: reserva.requirements.sector ?? '',
    buyOrder: reserva.requirements.buyOrder ?? '',
    employeeNumber: reserva.requirements.employeeNumber ?? '',
    platformApplication: reserva.requirements.platformApplication ?? '',
    tripNumber: reserva.requirements.tripNumber ?? '',
    canalIngreso: reserva.entry_channel ?? '',
    plataformaId: reserva.platform_id ? String(reserva.platform_id) : '',
    prioridad: reserva.has_priority,
    tipoServicio: reserva.service_type,
    observacionCoordinacion: reserva.coordination_observation ?? '',
    fixedRate,
    hasOutSite: reserva.has_out_site,
    data1: reserva.requirements.data1,
    data2: reserva.requirements.data2,
    data3: reserva.requirements.data3,
    fechaYHoraDeLlegada: moment()
      .hours(reserva.reservation_hours)
      .minutes(reserva.reservation_minutes),
    minutosPreviosASalida: moment()
      .hours(reserva.reservation_hours)
      .minutes(reserva.reservation_minutes)
      .add(-reserva.advance_hours, 'hours'),
    puntos: reserva.puntos.map((p) => ({
      domicilio: p.domicilio,
      espera: p.minutosEspera ? String(p.minutosEspera) : '0',
      observacion: p.observacion ?? '',
      pasajeros: p.pasajeros
    })),
    repeatOnMonday: reserva.monday,
    repeatOnTuesday: reserva.tuesday,
    repeatOnWednesday: reserva.wednesday,
    repeatOnThursday: reserva.thursday,
    repeatOnFriday: reserva.friday,
    repeatOnSaturday: reserva.saturday,
    repeatOnSunday: reserva.sunday,
    repetitionFinishDate: reserva.to_date,
    repetitionInitDate: reserva.from_date,
    pay_with_cash: reserva.pay_with_cash,
    isEvent: reserva.is_event,
    nameEvent: reserva.name_event ?? '',
    isLLTPais: reserva.isLLTPais ? true : false,
    customerPrice: reserva?.customer_price || 0,
    providerPrice: reserva?.provider_price || 0
  };
}

export const transferReservationRepetitiveFormBuilder = {
  toModel: reservaTrasladoRepetitiveFormToModel,
  toForm: reservaRepetitiveModelToForm
};
