import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';

type Props = {
  data: any;
  index: number;
  isMobileOrTablet: boolean;
};

const ServiceType = ({ data, index, isMobileOrTablet }: Props) => {
  return (
    <div className="flex justify-between text-sm">
      {isMobileOrTablet ? <p>Servicio</p> : null}
      <div className="flex items-center space-x-2">
        <p className="text-xs">
          {data[index].serviceType === ViajeTrasladoTipoServicio.EJECUTIVO
            ? null
            : data[index].serviceType}
        </p>{' '}
        {data[index].hasPriority && (
          <FontAwesomeIcon icon={faStar} color="orange" />
        )}
      </div>
    </div>
  );
};

export default ServiceType;
