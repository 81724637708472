import {
  rateBuilder,
  RateModel
} from 'app/client/clientBusiness/rates/models/RateModel';
import useUsersRepository from 'app/user/services/UserService';
import { notDeleted } from 'shared/helpers/deletedHandler';
import { httpClient } from 'shared/services/http/httpClient';
import {
  clientBusinessBuilder,
  ClientBusinessCrud,
  ClientBusinessModel,
  clientBusinessPartialBuilder,
  ClientBusinessPartialModel,
  IVA_CONDITIONS
} from '../models/ClientBusiness';
import { ClientBusinessUserModel } from '../user/models/ClientBusinessUser';

const useClientBusiness = () => {
  const userRepository = useUsersRepository();
  const getIvaConditions = async () => IVA_CONDITIONS;

  const getAllWithDeleted = async (): Promise<ClientBusinessPartialModel[]> => {
    const res = await httpClient.get(`business-clients`);
    return res.map(clientBusinessPartialBuilder.fromBackEnd);
  };

  const getAll = async () => (await getAllWithDeleted()).filter(notDeleted);

  const get = async (id): Promise<ClientBusinessModel> => {
    const res = await httpClient.get(`business-clients/${id}`);
    return clientBusinessBuilder.fromBackEnd(res);
  };

  const create = async (client: ClientBusinessCrud) => {
    return httpClient.post(
      `business-clients`,
      clientBusinessBuilder.toBackEnd(client)
    );
  };

  const edit = async (client: ClientBusinessCrud) => {
    return httpClient.put(
      `business-clients/${client.id}`,
      clientBusinessBuilder.toBackEnd(client)
    );
  };

  const remove = (id: number) => httpClient.delete(`business-clients/${id}`);

  const restore = (id: number) =>
    httpClient.put(`business-clients/${id}/restore`);

  const findByName = async (name: string) => {
    return (await getAll()).filter((client) =>
      client.fantasyName.toLowerCase().includes(name.toLowerCase())
    );
  };

  const getCurrentRate = async (
    idClientBusiness: number
  ): Promise<RateModel> => {
    const res = await httpClient.get(
      `business-clients/${idClientBusiness}/rates/current`
    );
    return rateBuilder.fromBackEnd(res);
  };

  const getRates = async (): Promise<RateModel> => {
    const res = await httpClient.get(`business-clients/rates`);
    return rateBuilder.fromBackEnd(res);
  };

  const exportBusinessClient = async () => {
    const res = await httpClient.get(`business-clients/export`, {
      responseType: 'blob'
    });
    return res;
  };

  const addUser = (values: {
    email: string;
    name: string;
    is_admin?: boolean | number;
    client_id: number;
  }) => httpClient.post(`business-clients/${values.client_id}/users`, values);

  const updateUser = (user: {
    name: string;
    email: string;
    roles?: string[];
    id?: number;
  }) => {
    return httpClient.put('users/' + user.id, {
      email: user.email,
      name: user.name,
      roles: user.roles
    });
  };

  const updateUserCompany = (user_id: string, company_id: number) => {
    return httpClient.put(
      `users/${user_id}/change-client-user?client_id=${company_id}`
    );
  };

  const changeUserPassword = userRepository.changePassword;

  const removeUser = userRepository.removeUser;
  const restoreUser = userRepository.restoreUser;

  const getAllUsers = (clientId: number): Promise<ClientBusinessUserModel[]> =>
    httpClient.get(`/users?client_id=${clientId}`);

  const importUsers = (values: {
    file: File;
    clientId: number;
  }): Promise<void> => {
    const formData = new FormData();
    formData.append('file', values.file);
    return httpClient.post(
      `business-clients/${values.clientId}/import-users`,
      formData
    );
  };

  return {
    getAll,
    getAllWithDeleted,
    getIvaConditions,
    getCurrentRate,
    create,
    get,
    edit,
    remove,
    restore,
    findByName,
    exportBusinessClient,
    addUser,
    updateUser,
    updateUserCompany,
    changeUserPassword,
    removeUser,
    restoreUser,
    getAllUsers,
    importUsers
  };
};

export default useClientBusiness;
