import React from 'react';

type Props = {
  data: any;
  index: number;
  isMobileOrTablet: boolean;
  onShowDriverDetails: (driver: any) => void;
};

const Driver = ({
  data,
  index,
  isMobileOrTablet,
  onShowDriverDetails
}: Props) => {
  return (
    <div className="flex justify-between text-sm">
      {isMobileOrTablet ? <p>Chofer</p> : null}
      <div className="flex flex-col items-center text-sm">
        <p
          className="cursor-pointer hover:underline"
          onClick={() => onShowDriverDetails(data[index].preAssignedDriver!)}
        >
          {data[index].preAssignedDriver?.codigo}
        </p>
        <p className="w-28 text-center text-xs">
          {data[index].preAssignedDriver?.nombreCompleto}
        </p>
      </div>
    </div>
  );
};

export default Driver;
