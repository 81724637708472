import { Table } from '@vadiun/react-datatable';
import { Moment } from 'moment';
import { DriverIncomeModel } from '../models/DriverIncomeModel';
interface Props {
  incomes: DriverIncomeModel[];
  visibleColumns: {
    average_income_per_day: boolean;
    average_income_per_hour: boolean;
    income_in_period: boolean;
    worked_hours_in_period: boolean;
    shift_open_date_time: boolean;
    total_days_worked: boolean;
    today_income: boolean;
    last_shift_open: boolean;
    today_income_per_worked_hour: boolean;
  };
  onColumnsVisibleChange: (columns: {
    average_income_per_day: boolean;
    average_income_per_hour: boolean;
    income_in_period: boolean;
    worked_hours_in_period: boolean;
    shift_open_date_time: true;
    last_shift_open: boolean;
    total_days_worked: boolean;
    today_income: boolean;
    today_income_per_worked_hour: boolean;
  }) => void;
}

export const DriverIncomeTable = ({
  incomes,
  visibleColumns,
  onColumnsVisibleChange
}: Props) => {
  const columns = [
    {
      name: 'code',
      label: 'Código',
      options: {
        filter: false
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false
      }
    },
    {
      name: 'shift_open_date_time',
      label: 'Inicio turno',
      options: {
        filter: false,
        display: visibleColumns.shift_open_date_time,
        customBodyRender: (date: Moment | null) =>
          date?.format('DD/MM/YYYY HH:mm')
      }
    },
    {
      name: 'business_unit_name',
      label: 'Unidad de Negocio'
    },
    {
      name: 'today_income',
      label: 'Total - día actual',
      options: {
        display: visibleColumns.today_income,
        filter: false
      }
    },
    {
      name: 'today_income_per_worked_hour',
      label: 'Por hora - día actual',
      options: {
        display: visibleColumns.today_income_per_worked_hour,
        filter: false
      }
    },
    {
      name: 'last_shift_open',
      label: 'Fecha de último turno',
      options: {
        display: visibleColumns.last_shift_open,
        filter: false
      }
    },
    {
      name: 'total_days_worked',
      label: 'Días totales trabajados',
      options: {
        display: visibleColumns.last_shift_open,
        filter: false
      }
    },
    {
      name: 'average_income_per_day',
      label: 'Promedio por día',
      options: {
        filter: false,
        display: visibleColumns.average_income_per_day
      }
    },
    {
      name: 'average_income_per_hour',
      label: 'Promedio por hora',
      options: {
        filter: false,
        display: visibleColumns.average_income_per_hour
      }
    },
    {
      name: 'income_in_period',
      label: 'Total en periodo',
      options: {
        filter: false,
        display: visibleColumns.income_in_period
      }
    },
    {
      name: 'worked_hours_in_period',
      label: 'Horas trabajadas en periodo',
      options: {
        filter: false,
        display: visibleColumns.worked_hours_in_period
      }
    }
  ];
  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none',
          filter: false,
          download: true,
          print: false,
          onViewColumnsChange: (changedColumn, action) => {
            onColumnsVisibleChange({
              ...visibleColumns,
              [changedColumn]: action === 'add'
            } as any);
          }
        }}
        title={'Choferes'}
        data={incomes}
        columns={columns}
      />
    </div>
  );
};
