import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import moment, { Moment } from 'moment';

export interface LiquidacionModel {
  id: number;
  datetime: Moment;
  client: {
    id: number;
    name: string;
    hasPriority: boolean;
  };
  total_transfer_price: number;
  total_expenses: number;
  total_price: number;
  authorized?: string | AuthorizedModel;
  business_unit_breakdown?: {
    lltpais: number;
    envios: number;
    remises: number;
    total: number;
  };
}

export const liquidacionBuilder = {
  fromBackEnd: (liquidacion): LiquidacionModel => {
    return {
      ...liquidacion,
      datetime: moment(liquidacion.datetime)
    };
  }
};
