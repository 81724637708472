import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import {
  BusinessUnitType,
  useBusinessUnit
} from 'app/business/services/useBusinessUnit';
import useChoferes from 'app/chofer/services/ChoferService';
import useAuthorizeds from 'app/client/clientBusiness/Authorized/services/AuthorizedService';
import { ClientBusinessPartialModel } from 'app/client/clientBusiness/models/ClientBusiness';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import useClientPrivates from 'app/client/private/private/services/ClientPrivateService';
import {
  TIPOS_SERVICIO_VIAJE_TRASLADO,
  ViajeTrasladoTipoServicio,
  VIAJE_TRASLADO_ESTADOS
} from 'app/viaje/models';
import { Field, Form, Formik, FormikProps } from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { KeyboardDateTimePicker } from 'formik-material-ui-pickers';
import { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';
import * as Yup from 'yup';
import { FilterCategoryTitle } from './FilterCategoryTitle';

export type TransferReservationBaseFiltersFormType = {
  dateFrom: Moment;
  dateTo: Moment;
  clientId: number | null;
  serviceType: ViajeTrasladoTipoServicio | 'Todos';
  hasPriority: boolean;
  passangerName: string;
  street: string;
  locality?: string;
  phone: string;
  client_phone: string;
  client_type?: 'BusinessClient' | 'ParticularClient';
  reservationId: string;
  oficial_cuenta_id?: null | number;
  authorized_id?: number | null;
  cost_center?: string;
  sub_cost_center?: string;
  buy_order?: string;
  sector?: string;
  driver_with_close_shift?: boolean;
  departure_time_exceeded?: boolean;
  assigned_driver_id?: number | null;
  pre_assigned_driver_id?: number | null;
  driverCode?: '';
  state?: string;
  plataformaId?: string;
  page?: number;
  page_size?: number;
  business_unit?: BusinessUnitType;
  sent_platform?: string;
  is_event: boolean;
  event_name?: string;
  is_llt_pais: boolean;
  sent_reservations_platform: boolean;
  not_sent_reservations_platform: boolean;
  has_platform_id: boolean;
};

export const ViajeTrasladoBaseFiltersSchema = Yup.object({
  dateFrom: Yup.mixed(),
  dateTo: Yup.mixed(),
  clientId: Yup.number().nullable(),
  serviceType: Yup.string(),
  hasPriority: Yup.boolean(),
  passangerName: Yup.string(),
  street: Yup.string(),
  locality: Yup.string(),
  phone: Yup.string(),
  client_phone: Yup.string(),
  reservationId: Yup.string(),
  cost_center: Yup.string(),
  sub_cost_center: Yup.string(),
  buy_order: Yup.string(),
  sector: Yup.string(),
  driver_with_close_shift: Yup.boolean(),
  departure_time_exceeded: Yup.boolean(),
  business_unit: Yup.mixed(),
  sent_reservations_platform: Yup.boolean(),
  not_sent_reservations_platform: Yup.boolean(),
  has_platform_id: Yup.boolean(),
  is_event: Yup.boolean(),
  is_llt_pais: Yup.boolean(),
  event_name: Yup.string()
});

interface Props {
  onSubmit: (values: TransferReservationBaseFiltersFormType) => Promise<void>;
  initialValues: TransferReservationBaseFiltersFormType;
  filterByClient?: boolean;
  filterByAssignedDriver?: boolean;
  filterByPreAssignedDriver?: boolean;
}

const ViajeTrasladoBaseFilters = ({
  onSubmit,
  initialValues,
  filterByClient = false,
  filterByAssignedDriver = true,
  filterByPreAssignedDriver = false
}: Props) => {
  const authorizadosService = useAuthorizeds();
  const driverRepository = useChoferes();
  const clientBusinessRepository = useClientBusiness();
  const ClientPrivateService = useClientPrivates();
  const BusinessUnit = useBusinessUnit();

  const [clientIdSelected, setClientIdSelected] = useState<number | undefined>(
    undefined
  );

  const clientesQuery = useSuperQuery(clientBusinessRepository.getAll);
  const businessQuery = useSuperQuery(BusinessUnit.getBusinessUnit);
  const clientprivatesQuery = useQuery(
    ['private-clients'],
    () => ClientPrivateService.getClientPrivates(),
    {
      enabled: filterByClient
    }
  );

  const autorizadosQuery = useSuperQuery(
    async () => {
      return clientIdSelected
        ? await authorizadosService.getAuthorizeds(clientIdSelected)
        : [];
    },
    undefined,
    [clientIdSelected]
  );

  const driversQuery = useSuperQuery(driverRepository.getChoferes);

  const formRef =
    useRef<FormikProps<TransferReservationBaseFiltersFormType>>(null);
  const prevClientRef = useRef('BusinessClient');

  useEffect(() => {
    prevClientRef.current = formRef.current?.values.client_type!;
  }, [formRef.current?.values.client_type]);

  useEffect(() => {
    if (prevClientRef.current !== formRef.current?.values.client_type!) {
      formRef.current?.setValues({
        ...formRef.current?.values,
        clientId: null
      });
    }
  }, [formRef.current?.values.client_type]);

  return (
    <>
      <Formik<TransferReservationBaseFiltersFormType>
        initialValues={initialValues}
        innerRef={formRef}
        enableReinitialize
        validationSchema={ViajeTrasladoBaseFiltersSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, touched, errors, setFieldValue, ...formik }) => (
          <Form className="max-w-lg gap-2 p-8">
            <FormikOnFieldChange<{
              client: ClientBusinessPartialModel | null;
            }>
              field="clientId"
              onChange={(client) => {
                setClientIdSelected(client);
                setFieldValue('authorizedId', null);
              }}
            />
            <FormikOnFieldChange<{
              sent_reservations_platform: boolean;
            }>
              field="sent_reservations_platform"
              onChange={(sent_reservations_platform) => {
                setFieldValue(
                  'sent_reservations_platform',
                  sent_reservations_platform
                );
                formik.values.not_sent_reservations_platform &&
                  sent_reservations_platform &&
                  setFieldValue('not_sent_reservations_platform', false);
              }}
            />
            <FormikOnFieldChange<{
              not_sent_reservations_platform: boolean;
            }>
              field="not_sent_reservations_platform"
              onChange={(value) => {
                setFieldValue('not_sent_reservations_platform', value);
                formik.values.sent_reservations_platform &&
                  value &&
                  setFieldValue('sent_reservations_platform', false);
              }}
            />
            <div>
              <div className="mb-2 border-b-2 border-dashed border-gray-100 pb-2">
                <FilterCategoryTitle title="Periodo" />
                <div className="grid grid-cols-2 gap-x-3">
                  <Field
                    component={KeyboardDateTimePicker}
                    label="Desde"
                    name="dateFrom"
                    ampm={false}
                    inputVariant="outlined"
                    margin="dense"
                    format="DD/MM/YYYY HH:mm"
                  />
                  <Field
                    component={KeyboardDateTimePicker}
                    label="Hasta"
                    name="dateTo"
                    ampm={false}
                    inputVariant="outlined"
                    margin="dense"
                    format="DD/MM/YYYY HH:mm"
                  />
                </div>
              </div>
              <div className="mb-2 border-b-2 border-dashed border-gray-100 pb-2">
                <FilterCategoryTitle title="Reserva" />
                <div className="grid grid-cols-2 gap-x-3">
                  {filterByClient && (
                    <div className="col-span-1">
                      <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel>Tipo de Cliente</InputLabel>
                        <Field
                          component={Select}
                          name="client_type"
                          label="Tipo de Cliente"
                        >
                          <MenuItem value="BusinessClient">Empresa</MenuItem>
                          <MenuItem value="ParticularClient">
                            Particular
                          </MenuItem>
                        </Field>
                      </FormControl>
                    </div>
                  )}

                  {clientesQuery.data && (
                    <Field
                      name="clientId"
                      component={Autocomplete}
                      noOptionsText="No se han encontrado resultados"
                      loadingText="Cargando..."
                      fullWidth
                      margin="dense"
                      options={
                        filterByClient &&
                        formik.values.client_type === 'ParticularClient'
                          ? clientprivatesQuery.data &&
                            (clientprivatesQuery.data ?? []).map((x) => x.id)
                          : (clientesQuery.data ?? []).map((x) => x.id)
                      }
                      getOptionLabel={(option: number) => {
                        if (
                          filterByClient &&
                          formik.values.client_type === 'ParticularClient'
                        ) {
                          const client = (clientprivatesQuery.data ?? []).find(
                            (x) => x.id === option
                          );

                          return `${client?.name} ${client?.lastName}` ?? '';
                        } else {
                          return (
                            (clientesQuery.data ?? []).find(
                              (x) => x.id === option
                            )?.fantasyName ?? ''
                          );
                        }
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={touched['clientId'] && !!errors['clientId']}
                          helperText={errors['clientId']}
                          label="Cliente"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                  )}

                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>Tipo de servicio</InputLabel>
                    <Field
                      component={Select}
                      name="serviceType"
                      label="Tipo de servicio"
                    >
                      {TIPOS_SERVICIO_VIAJE_TRASLADO.map((canal) => (
                        <MenuItem value={canal} key={canal}>
                          {canal}
                        </MenuItem>
                      ))}
                      <MenuItem value={'Todos'}>Todos</MenuItem>
                    </Field>
                  </FormControl>
                  <Field
                    name="street"
                    label="Calle"
                    variant="outlined"
                    component={TextField}
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    name="locality"
                    label="Localidad"
                    variant="outlined"
                    component={TextField}
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    name="reservationId"
                    label="Reserva ID"
                    variant="outlined"
                    component={TextField}
                    margin="dense"
                    type="number"
                    fullWidth
                  />
                  <Field
                    name="client_phone"
                    label="Teléfono del cliente"
                    variant="outlined"
                    component={TextField}
                    margin="dense"
                    type="number"
                    fullWidth
                  />
                  <div className="col-span-1">
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="hasPriority"
                      color="primary"
                      Label={{ label: 'Prioritario' }}
                      fullWidth
                    />
                  </div>
                  <div className="col-span-1 mb-1">
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="has_platform_id"
                      color="primary"
                      Label={{ label: 'Sol. Plataforma' }}
                    />
                  </div>
                  <div className="col-span-1 mb-1">
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="is_llt_pais"
                      color="primary"
                      Label={{ label: 'LLT-Pais' }}
                    />
                  </div>
                  <div className="col-span-1 mb-1">
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="is_event"
                      color="primary"
                      Label={{ label: 'Evento' }}
                    />
                  </div>
                  <div className="col-span-1 mb-1">
                    <Field
                      name="event_name"
                      label="Nombre del evento"
                      variant="outlined"
                      component={TextField}
                      margin="dense"
                      fullWidth
                    />
                  </div>
                  <div className="col-span-2" />
                  <div className="col-span-1 mt-2 mb-1 flex w-full flex-col justify-items-end">
                    <p className="text-gray-500">Cargado en plataforma:</p>
                    <div className="flex gap-4">
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="sent_reservations_platform"
                        color="primary"
                        Label={{ label: 'Si' }}
                      />
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="not_sent_reservations_platform"
                        color="primary"
                        Label={{ label: 'No' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-2 border-b-2 border-dashed border-gray-100 pb-2">
              <FilterCategoryTitle title="Requerimientos" />
              <div className="grid grid-cols-2 gap-x-3">
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <InputLabel>Autorizado</InputLabel>
                  <Field
                    component={Select}
                    disabled={autorizadosQuery.data?.length === 0}
                    name="authorized_id"
                    label="Autorizado"
                  >
                    <MenuItem value={undefined}>Todos</MenuItem>
                    {autorizadosQuery.data?.map((authorized) => (
                      <MenuItem value={authorized.id} key={authorized.id}>
                        {authorized.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <div className="col-span-1">
                  <Field
                    component={TextField}
                    type="text"
                    name="cost_center"
                    color="primary"
                    label="Centro de costos"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className="col-span-1">
                  <Field
                    component={TextField}
                    type="text"
                    name="sub_cost_center"
                    color="primary"
                    label="Sub Centro de costos"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className="col-span-1">
                  <Field
                    component={TextField}
                    type="text"
                    name="sector"
                    label="Sector"
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className="col-span-1">
                  <Field
                    component={TextField}
                    type="text"
                    label="Orden de compra"
                    variant="outlined"
                    name="buy_order"
                    color="primary"
                    margin="dense"
                    fullWidth
                  />
                </div>
                {/*<div>
                  {driversQuery.data && (
                    <Field
                      name="oficial_cuenta_id"
                      component={Autocomplete}
                      noOptionsText="No se han encontrado resultados"
                      loadingText="Cargando..."
                      fullWidth
                      margin="dense"
                      options={(oficialesCuentaQuery.data ?? []).map(
                        (x) => x.id
                      )}
                      getOptionLabel={(option: number) => {
                        const oficial = oficialesCuentaQuery.data?.find(
                          (x) => x.id === option
                        );
                        return oficial ? oficial.name : '';
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={
                            touched['oficial_cuenta_id'] &&
                            !!errors['oficial_cuenta_id']
                          }
                          helperText={errors['oficial_cuenta_id']}
                          label="Oficial de cuenta"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                  )}
                        </div>*/}
                <Field
                  name="plataformaId"
                  label="ID plataforma"
                  variant="outlined"
                  component={TextField}
                  margin="dense"
                  fullWidth
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="col-span-2"
                  margin="dense"
                >
                  <InputLabel>Estado</InputLabel>
                  <Field component={Select} name="state" label="Estado">
                    {VIAJE_TRASLADO_ESTADOS.map((state) => (
                      <MenuItem value={state} key={state}>
                        {state}
                      </MenuItem>
                    ))}
                    <MenuItem value={'Todos'}>Todos</MenuItem>
                  </Field>
                </FormControl>
              </div>
            </div>

            <div className="mb-2 border-b-2 border-dashed border-gray-100 pb-2">
              <FilterCategoryTitle title="Pasajero" />
              <div className="grid grid-cols-2 gap-x-3">
                <Field
                  name="passangerName"
                  label="Pasajero"
                  variant="outlined"
                  component={TextField}
                  margin="dense"
                  fullWidth
                />
                <Field
                  name="phone"
                  label="Teléfono pasajero"
                  variant="outlined"
                  component={TextField}
                  margin="dense"
                  fullWidth
                />
              </div>
            </div>
            <FilterCategoryTitle title="Chofer" />
            <div className="grid">
              {driversQuery.data && (
                <>
                  {filterByAssignedDriver && (
                    <Field
                      name="assigned_driver_id"
                      component={Autocomplete}
                      noOptionsText="No se han encontrado resultados"
                      loadingText="Cargando..."
                      fullWidth
                      margin="dense"
                      options={(driversQuery.data ?? []).map((x) => x.id)}
                      getOptionLabel={(option: number) => {
                        const driver = driversQuery.data?.find(
                          (x) => x.id === option
                        );
                        return driver
                          ? `#${driver.codigo} - ${driver.nombreCompleto}`
                          : '';
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={
                            touched['assigned_driver_id'] &&
                            !!errors['assigned_driver_id']
                          }
                          helperText={errors['assigned_driver_id']}
                          label="Chofer asignado"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                  )}

                  {filterByPreAssignedDriver && (
                    <Field
                      name="pre_assigned_driver_id"
                      component={Autocomplete}
                      noOptionsText="No se han encontrado resultados"
                      loadingText="Cargando..."
                      fullWidth
                      margin="dense"
                      options={(driversQuery.data ?? []).map((x) => x.id)}
                      getOptionLabel={(option: number) => {
                        const driver = driversQuery.data?.find(
                          (x) => x.id === option
                        );
                        return driver
                          ? `#${driver.codigo} - ${driver.nombreCompleto}`
                          : '';
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={
                            touched['pre_assigned_driver_id'] &&
                            !!errors['pre_assigned_driver_id']
                          }
                          helperText={errors['pre_assigned_driver_id']}
                          label="Chofer pre asignado"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                  )}
                </>
              )}
              {businessQuery.data && (
                <>
                  {filterByAssignedDriver && (
                    <Field
                      name="business_unit"
                      component={Autocomplete}
                      noOptionsText="No se han encontrado resultados"
                      loadingText="Cargando..."
                      fullWidth
                      margin="dense"
                      options={(businessQuery.data ?? []).map((x) => x.id)}
                      getOptionLabel={(option: number) => {
                        const businessUnit = businessQuery.data?.find(
                          (x) => x.id === option
                        );
                        return businessUnit ? businessUnit.name : '';
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={
                            touched['business_unit'] &&
                            !!errors['business_unit']
                          }
                          helperText={errors['business_unit']}
                          label="Unidad de Negocio"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                  )}

                  {filterByPreAssignedDriver && (
                    <Field
                      name="pre_assigned_driver_id"
                      component={Autocomplete}
                      noOptionsText="No se han encontrado resultados"
                      loadingText="Cargando..."
                      fullWidth
                      margin="dense"
                      options={(driversQuery.data ?? []).map((x) => x.id)}
                      getOptionLabel={(option: number) => {
                        const driver = driversQuery.data?.find(
                          (x) => x.id === option
                        );
                        return driver
                          ? `#${driver.codigo} - ${driver.nombreCompleto}`
                          : '';
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={
                            touched['pre_assigned_driver_id'] &&
                            !!errors['pre_assigned_driver_id']
                          }
                          helperText={errors['pre_assigned_driver_id']}
                          label="Chofer pre asignado"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                  )}
                </>
              )}
              <div className="col-span-1">
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="driver_with_close_shift"
                  color="primary"
                  Label={{ label: 'Chofer con turno cerrado' }}
                  fullWidth
                />
              </div>
              <div className="col-span-1">
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="departure_time_exceeded"
                  color="primary"
                  Label={{ label: 'Horario de salida superado' }}
                  fullWidth
                />
              </div>
            </div>

            <div className="col-span-1 flex justify-end pt-4">
              <Button onClick={submitForm} variant="contained">
                Buscar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ViajeTrasladoBaseFilters;
