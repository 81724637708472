// @flow
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import { Button, Chip, TableColumn } from '@vadiun/react-components';
import { Table, UseTableQueryResult } from '@vadiun/react-datatable';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';
import * as React from 'react';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationModel } from '../../viajeTraslado/models/TransferReservation';
import { tw } from 'utils/tailwindUtils';
interface Props {
  tableQuery: UseTableQueryResult<TransferReservationModel[], unknown>;
  onLocationsDetails: (reservation: TransferReservationModel) => void;
  onApproveReservationPrices?: (travel: TransferReservationModel) => void;
  onCorrectReservationPrices: (reservation: TransferReservationModel) => void;
  onReservationShowDetails: (id: number) => React.ReactNode;
  isAdminPage: boolean;
  onCancelReservation?: (reservation: TransferReservationModel) => void;
}

interface IAdaptedData extends TransferReservationModel {
  client_name: string;
  passengers: string[];
}

export function ViajeTrasladoPendienteAuditoriaTable({
  tableQuery,
  onLocationsDetails,
  onApproveReservationPrices,
  onCorrectReservationPrices,
  onReservationShowDetails,
  onCancelReservation,
  isAdminPage
}: Props) {
  const data = (tableQuery.data ?? []).map((el) => {
    return {
      ...el,
      client_name: el.client.name,
      passengers: el.locations
        .map((location) =>
          location.passengers.map((passenger) => passenger.name)
        )
        .flat()
    };
  });

  const columns: TableColumn<IAdaptedData>[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        download: false
      }
    },
    {
      name: 'client',
      label: 'Cliente',
      options: {
        download: false,
        customBodyRenderLite: (index) => {
          const current = data[index];
          return (
            <div className="flex flex-col gap-2">
              <p
                className={`w-44 text-sm ${
                  !current.pay_with_cash ? 'text-blue-500' : 'text-black'
                }`}
              >
                {current.client.name}
              </p>
              <div
                className={tw('hidden flex-col gap-1', {
                  flex: current.lltpais ? true : false
                })}
              >
                <div className="flex text-sm w-fit text-blue-100 font-bold px-2 py-1 bg-blue-900 rounded-lg mb-1">
                  LLT-País
                </div>
                <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
                  Proveedor
                  <span>${Number(current?.lltpais?.provider_price ?? 0)}</span>
                </p>
                <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
                  Cliente
                  <span>${Number(current?.lltpais?.customer_price ?? 0)}</span>
                </p>
              </div>
            </div>
          );
        }
      }
    },
    {
      name: 'client_name',
      label: 'Cliente',
      options: {
        display: false
      }
    },
    {
      name: 'reservationDatetime',
      label: 'Fecha',
      options: {
        download: false,
        customBodyRenderLite: (index) =>
          data[index].reservationDatetime.format('DD/MM/YYYY')
      }
    },
    {
      name: 'locations',
      label: 'Domicilios',
      options: {
        download: false,
        customBodyRenderLite: (index) => {
          return (
            <div className="flex w-40 flex-col items-start">
              {data[index].locations.map((location, index) => (
                <div className="mb-1 flex items-start">
                  <div className="flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    {index + 1}
                  </div>
                  <span className="ml-1">
                    {createDomicilioText(location.address)}
                  </span>
                </div>
              ))}
              <Button
                shape="circle"
                className="w-full p-2"
                onClick={() => onLocationsDetails(data[index])}
                color="blue"
              >
                <div className="flex items-center gap-1">Info</div>
              </Button>
            </div>
          );
        }
      }
    },
    {
      name: 'coordinationObservation',
      label: 'Obs. Coordinación',
      options: {
        download: true
      }
    },
    {
      name: 'deviation_observation',
      label: 'Obs. Desvio',
      options: {
        download: true
      }
    },
    {
      name: 'deviation_observation',
      label: 'Motivo',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <p>{data[index].states[data[index].states.length - 1].observation}</p>
        )
      }
    },
    {
      name: 'platformId',
      label: 'ID plataforma',
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex justify-between text-sm">
              <p className="text-xs">{value}</p>
            </div>
          );
        }
      }
    },
    {
      name: 'requirements',
      label: 'Sol. Plataforma',
      options: {
        download: false,
        customBodyRenderLite: (index) =>
          data[index].requirements?.platformApplication
      }
    },
    {
      name: 'serviceType',
      label: 'Servicio',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="flex items-center space-x-2">
            <p>
              {data[index].serviceType === ViajeTrasladoTipoServicio.EJECUTIVO
                ? null
                : data[index].serviceType}
            </p>{' '}
            {data[index].hasPriority && (
              <FontAwesomeIcon icon={faStar} color="orange" />
            )}
          </div>
        )
      }
    },
    {
      name: 'event_name',
      label: 'Evento',
      options: {
        download: false,
        customBodyRenderLite: (index) => {
          const { is_event, event_name } = data[index];
          return (
            <div className="flex items-center space-x-2">
              <p>{is_event ? event_name : ''}</p>
            </div>
          );
        }
      }
    },
    {
      name: 'passengers',
      label: 'Pasajeros',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="flex flex-col">
            {data[index].passengers.map((el) => (
              <span>{el}</span>
            ))}
          </div>
        )
      }
    },
    {
      name: 'calculated_prices',
      label: 'Precios',
      options: {
        download: false,
        setCellProps: () => ({ style: { minWidth: '180px' } }),
        customBodyRenderLite: (index) => (
          <div>
            <Chip>{data[index].calculated_prices!.rate_type}</Chip>
            <div className="mt-3 grid grid-cols-3 gap-1">
              <span className="col-span-1 mr-2 text-xs text-gray-400">KM</span>
              <span className=" col-span-1 font-semibold text-green-400">
                ${data[index].calculated_prices!.km_price}
              </span>
              <span className="col-span-1 ml-3 text-black">
                {data[index].calculated_prices!.kilometers}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Espera
              </span>
              <span className=" col-span-1 font-semibold text-green-400">
                ${data[index].calculated_prices!.waiting_minutes_price}
              </span>

              <span className="col-span-1 ml-3 text-black">
                {data[index].calculated_prices!.waiting_minutes}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Out-Site
              </span>
              <span className=" col-span-2 font-semibold text-green-400">
                ${data[index].calculated_prices!.out_site_price}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Plus-nocturno
              </span>
              <span className=" col-span-2 font-semibold text-green-400">
                ${data[index].calculated_prices!.night_plus_price}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Gastos
              </span>
              <span className=" col-span-2 font-semibold text-red-400">
                ${data[index].calculated_prices!.expenses_price}
              </span>
              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Total
              </span>
              <span className=" col-span-2 font-semibold text-blue-400">
                $
                {data[index].calculated_prices!.km_price +
                  data[index].calculated_prices!.waiting_minutes_price +
                  data[index].calculated_prices!.out_site_price +
                  data[index].calculated_prices!.night_plus_price +
                  data[index].calculated_prices!.expenses_price}
              </span>
            </div>
          </div>
        )
      }
    },

    {
      label: 'Acciones',
      name: 'id',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="space-y-1">
            {onReservationShowDetails(data[index].id)}
            {!isAdminPage ? (
              <Button
                onClick={() =>
                  onApproveReservationPrices &&
                  onApproveReservationPrices(data[index])
                }
                variant="light"
                className="w-full"
                color="green"
              >
                Aprobar
              </Button>
            ) : null}
            <Button
              onClick={() => onCorrectReservationPrices(data[index])}
              variant="light"
              className="w-full"
              color="red"
            >
              Corregir
            </Button>
            <Button
              onClick={() => onCancelReservation!(data[index])}
              variant="light"
              className="w-full"
              color="red"
            >
              Cancelar
            </Button>
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        title={
          tableQuery.isFetching && (
            <div className="flex items-center gap-4">
              <CircularProgress size={30} />
              <p className="text-gray-500">Cargando...</p>
            </div>
          )
        }
        data={data}
        options={{
          elevation: 0,
          selectableRows: 'none',
          search: false,
          filter: false,
          sort: false,
          print: false,
          ...tableQuery.serverSideOptions
        }}
        columns={columns}
      />
    </div>
  );
}
