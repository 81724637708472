import moment from 'moment';
import { Moment } from 'moment';
import { TravelDurationEstimations } from './distanceAndPriceViajeTrasladoCalculator';

type Trip = { lat: number; lng: number }[];

const getOrigins = (trip: any[]) => trip.slice(0, -1);

const getDestinations = (trip: any[]) => trip.slice(1);

const formatDuration = (duration: google.maps.Duration) => {
  return duration.value / 60;
};

const formatDistance = (distance: google.maps.Distance) => {
  return distance.value / 1000;
};

const toLatLng = (position: { lat: number; lng: number }) =>
  new google.maps.LatLng(position.lat, position.lng);

export const distanceCalculator = (trips: Trip, departureTime: Moment) =>
  new Promise<TravelDurationEstimations>((resolve) => {
    const service = new google.maps.DistanceMatrixService({
      avoidTolls: false
    });

    const formatedTrips = trips.map(toLatLng);
    const origins = getOrigins(formatedTrips);
    const destinations = getDestinations(formatedTrips);
    const departureTimeDate = departureTime.isBefore(moment())
      ? new Date()
      : departureTime.toDate();
    service.getDistanceMatrix(
      {
        origins,
        destinations,
        travelMode: google.maps.TravelMode.DRIVING,
        transitOptions: {
          departureTime: departureTimeDate
        },
        drivingOptions: {
          departureTime: departureTimeDate
        },
        unitSystem: google.maps.UnitSystem.METRIC
      },
      ({ rows }) => {
        const results = rows.reduce(
          (acum, { elements }, index) => {
            return {
              distance:
                acum.distance + formatDistance(elements[index].distance),
              duration:
                acum.duration + formatDuration(elements[index].duration),
              durationTraffic:
                acum.durationTraffic +
                formatDuration(elements[index].duration_in_traffic)
            };
          },
          { distance: 0, duration: 0, durationTraffic: 0 }
        );
        resolve(results);
      }
    );
  });
