import { TransferReservationPendingModel } from 'app/viaje/viajeTraslado/pendiente/models/TransferReservationPending';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';

type Props = {
  data: any;
  index: number;
  isMobileOrTablet: boolean;
  onLocationsDetails: (reservation: TransferReservationPendingModel) => void;
};

const Points = ({
  data,
  index,
  isMobileOrTablet,
  onLocationsDetails
}: Props) => {
  return (
    <div
      style={{
        width: '220px',
        maxWidth: '220px'
      }}
    >
      <div className="flex justify-between text-sm">
        {isMobileOrTablet ? <p>Domicilios</p> : null}
        <div className="flex flex-col items-start gap-1">
          {data[index].locations.map((location, index) => (
            <div className="mb-1 flex items-center gap-1">
              <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 p-2 text-xs text-white">
                {index + 1}
              </div>
              <p className="ml-1 text-xs leading-none text-gray-700">
                {createDomicilioText(location.address)}
              </p>
            </div>
          ))}
          <button
            className="mt-4 rounded-2xl border-2 text-sm leading-6 text-blue-500 hover:bg-gray-100 w-full"
            onClick={(e) => {
              e.stopPropagation();
              onLocationsDetails(data[index]);
            }}
          >
            Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default Points;
