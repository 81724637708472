import React, { RefObject } from 'react';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TableColumn } from '@vadiun/react-components';
import { Table } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { mapVehiculoCategoriaToIconColor } from '../../../vehiculos/models/VehiculoCategoria';
import { ChoferPartialModel } from '../../models/Chofer';
import ChoferSearch from './ChoferSearch';

interface Props {
  choferes: ChoferPartialModel[];
  loading: boolean;
  filters: any;
  searchRef: RefObject<{
    getSearch: () => string | null;
  }>;
  onChoferModifyButton: (id: number) => React.ReactNode;
  onChangeFilters: (filters: any) => void;
  onLoadDrivers: (searchText?: string) => void;
}

enum FilterFields {
  CODIGO = 'codigo',
  NOMBRE = 'nombreCompleto',
  VEHICULO = 'vehiculo',
  HAS_POSNET = 'hasPosnet'
}

const ChoferTable = ({
  choferes,
  loading,
  filters,
  searchRef,
  onChoferModifyButton,
  onChangeFilters,
  onLoadDrivers
}: Props) => {
  const columns: TableColumn<any>[] = [
    {
      name: FilterFields.CODIGO,
      label: 'Código',
      options: {
        filterType: 'textField'
      }
    },
    {
      name: 'foto',
      label: 'Foto',
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (index: number) => {
          return (
            <div className="relative inline-block">
              <FontAwesomeIcon
                icon={faMedal}
                className="absolute top-0 right-1"
                size="2x"
                color={mapVehiculoCategoriaToIconColor(
                  choferes[index].categoria
                )}
              />
              <img
                src={choferes[index].foto}
                className="h-24 w-24 rounded-lg object-cover object-center"
                alt=""
              />
            </div>
          );
        }
      }
    },
    {
      name: FilterFields.NOMBRE,
      label: 'Nombre',
      options: {
        filter: false
      }
    },
    {
      name: FilterFields.HAS_POSNET,
      label: 'Posnet',
      options: {
        filter: false,
        customBodyRender: (tienePosnet: boolean) =>
          tienePosnet ? (
            <div className="mt-3 w-1/2 rounded-md bg-green-100 p-1 text-center text-xs font-semibold text-green-400">
              Si
            </div>
          ) : (
            <div className="mt-3 w-1/2 rounded-md bg-red-100 p-1 text-center text-xs font-semibold text-red-400">
              No
            </div>
          )
      }
    },
    {
      name: 'vehiculo',
      label: 'Permiso MC',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (vehiculo: ChoferPartialModel['vehiculo']) => {
          const status = {
            yes: (
              <div className="mt-3 w-min rounded-md bg-green-100 px-3 py-1 text-center text-xs font-semibold text-green-400">
                Si
              </div>
            ),
            no: (
              <div className="mt-3 w-min rounded-md bg-red-100 px-3 py-1 text-center text-xs font-semibold text-red-400">
                No
              </div>
            )
          };
          return vehiculo.tieneMicrocentro ? status.yes : status.no;
        }
      }
    },
    {
      name: 'vehiculo',
      label: 'Foto',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (vehiculo: ChoferPartialModel['vehiculo']) => (
          <div className="relative inline-block">
            <FontAwesomeIcon
              icon={faMedal}
              className="absolute top-0 right-1"
              size="2x"
              color={mapVehiculoCategoriaToIconColor(vehiculo.categoria)}
            />
            <img
              src={vehiculo.foto}
              className="h-24 w-24 rounded-lg object-cover object-center"
              alt=""
            />
          </div>
        )
      }
    },
    {
      name: FilterFields.VEHICULO,
      label: 'Auto',
      options: {
        filter: false,
        customBodyRender: (vehiculo: ChoferPartialModel['vehiculo']) => (
          <div className="flex flex-col">
            <p className="text-sm font-medium text-gray-700">
              {vehiculo.modelo.nombre + '-' + vehiculo.modelo.fabricante.nombre}
            </p>
            <p className="text-xs font-medium text-gray-400">
              {vehiculo.patente}
            </p>
          </div>
        )
      }
    },
    {
      name: 'turnoDomicilio',
      label: 'Dom. Turno',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (
          turnoDomicilio: ChoferPartialModel['turnoDomicilio']
        ) => {
          return (
            <div className="flex flex-col">
              <p className="text-sm font-medium text-gray-700">
                {turnoDomicilio.localidad}
              </p>
              <p className="text-xs font-medium text-gray-400">
                {createDomicilioText(turnoDomicilio)}
              </p>
            </div>
          );
        }
      }
    },
    {
      name: 'isDeleted',
      label: 'Borrado',
      options: {
        display: false,
        filterOptions: {
          names: ['Si', 'No']
        },
        filterList: ['No'],
        customFilterListOptions: { render: (v) => `Borrado: ${v}` }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRender: (id: number) => (
          <div style={{ display: 'flex' }}>{onChoferModifyButton(id)}</div>
        )
      }
    }
  ];

  const data = choferes?.map((x) => ({
    ...x,
    isDeleted: x.isDeleted ? 'Si' : 'No'
  }));

  return (
    <div className={`w-full ${loading ? 'opacity-50' : ''}`}>
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none',
          print: false,
          download: false,
          serverSide: true,
          sort: true,
          onColumnSortChange: (changedColumn, direction) => {
            let sort = '';

            if (changedColumn === FilterFields.CODIGO) {
              sort = 'code';
            }

            if (changedColumn === FilterFields.NOMBRE) {
              sort = 'name';
            }

            if (changedColumn === FilterFields.VEHICULO) {
              sort = 'vehicle';
            }

            if (changedColumn === FilterFields.HAS_POSNET) {
              sort = 'hasPosnet';
            }

            onChangeFilters({
              ...filters,
              sort_by: sort,
              sort_order: direction
            });
          },
          onFilterConfirm: (filters) => {
            const code = filters[0];
            const isDeleted = filters[8];
            const hasCode = code && code.length > 0;
            const hasIsDeleted = isDeleted && isDeleted.length > 0;
            onChangeFilters({
              code: hasCode ? code[0] : null,
              isDeleted: hasIsDeleted ? isDeleted[0] : null
            });
          },
          confirmFilters: true,
          customFilterDialogFooter: (
            currentFilterList,
            applyFilters = () => {}
          ) => {
            return (
              <div className="flex items-center justify-end mt-4">
                <Button onClick={() => applyFilters()}>APLICAR</Button>
              </div>
            );
          },
          customSearchRender: (search) => {
            return (
              <ChoferSearch
                searchText={search}
                onLoadDrivers={onLoadDrivers}
                ref={searchRef}
              />
            );
          }
        }}
        title={'Choferes'}
        data={data}
        columns={columns}
      />
    </div>
  );
};

export default ChoferTable;
