import { Button } from '@vadiun/react-components';
import React, { FunctionComponent } from 'react';
import { useSettlements } from '../hooks/useSettlements';

type ActionsTableProps = {
  onDeleteReservations: (id: string) => void;
  onDeleteLiquidacion: (id: string) => void;
  settlementId: string;
  client: string;
};

const ActionsTable: FunctionComponent<ActionsTableProps> = ({
  onDeleteLiquidacion,
  onDeleteReservations,
  settlementId,
  client
}) => {
  const { downloadSettlements, isLoading } = useSettlements();

  return (
    <div className="flex flex-col gap-1 w-36 ml-auto">
      <Button
        variant="light"
        className="w-full"
        color="green"
        onClick={() => onDeleteReservations(settlementId)}
      >
        Modificar
      </Button>
      <Button
        variant="light"
        className="w-full"
        color="red"
        onClick={() => onDeleteLiquidacion(settlementId)}
      >
        Borrar
      </Button>
      <Button
        variant="light"
        className={'w-full'}
        color="blue"
        onClick={() => downloadSettlements(settlementId, client)}
        isLoading={isLoading}
      >
        Descargar
      </Button>
    </div>
  );
};

export default ActionsTable;
