import { TextField } from '@material-ui/core';
import React, { useState, forwardRef, useImperativeHandle } from 'react';

type Props = {
  searchText: string | null;
  onLoadDrivers: (searchText?: string) => void;
};

const ChoferSearch = forwardRef((props: Props, ref) => {
  const { searchText, onLoadDrivers } = props;
  const [search, setSearch] = useState(searchText || '');

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onSearchDrivers = () => {
    onLoadDrivers(search);
  };

  // Exponer la función `getSearch` mediante `useImperativeHandle`
  useImperativeHandle(ref, () => ({
    getSearch: () => search
  }));

  return (
    <div className="w-full flex gap-3">
      <TextField
        label="Buscar chofer"
        variant="outlined"
        size="small"
        value={search}
        onChange={onChangeText}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            onSearchDrivers();
          }
        }}
      />
      <button
        className="bg-blue-500 py-1 px-3 text-white rounded-lg"
        onClick={onSearchDrivers}
      >
        Buscar
      </button>
    </div>
  );
});

export default ChoferSearch;
