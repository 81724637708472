import ClearIcon from '@material-ui/icons/Clear';

import { Avatar, Dialog, Divider, IconButton } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Button } from '@vadiun/react-components';
import { useNotificationsContext } from 'shared/context/notificationContext';
import { tw } from 'utils/tailwindUtils';
import moment from 'moment';

const DriversRequestsRejectTravelMenu = (): JSX.Element => {
  const { travelsRejected: travels } = useNotificationsContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { length: sizeTravelsRejected } = travels || [];
  const hasNotTravelsRejected = sizeTravelsRejected === 0;

  const handleClick = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (hasNotTravelsRejected) {
      // setIsOpen(false);
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNotTravelsRejected]);

  return (
    <>
      <div
        className={tw(
          'flex-1 flex items-center gap-6 justify-between py-0 pl-2 pr-5 rounded-lg duration-500 cursor-pointer hover:bg-gray-100',
          {
            'bg-orange-50': sizeTravelsRejected
          }
        )}
        onClick={hasNotTravelsRejected ? undefined : handleClick}
      >
        <p
          className={tw(
            'duration-500 text-gray-400 text-xs leading-none w-56 flex items-center',
            {
              'text-orange-500': sizeTravelsRejected
            }
          )}
        >
          {sizeTravelsRejected ? (
            <span className="duration-300 relative flex h-3 w-3 mr-2">
              <span className="bg-orange-300 animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"></span>
              <span className="bg-orange-400 relative inline-flex rounded-full h-3 w-3"></span>
            </span>
          ) : (
            <div className="h-3 w-3 ml-2" />
          )}
          Rechazados
        </p>
        <span
          className={tw(
            'text-sm text-gray-700 duration-300 w-6 h-6 rounded-2xl flex justify-center items-center'
          )}
        >
          {sizeTravelsRejected}
        </span>
      </div>
      <Dialog open={isOpen} onClose={onCloseModal} maxWidth="md">
        <div className="px-4 py-4" style={{ width: 950, overflow: 'hidden' }}>
          <div className="mb-3 flex justify-between">
            <p className="text-lg text-gray-800">
              Viajes rechazados por choferes
            </p>
            <IconButton
              size="small"
              aria-label="close modal"
              onClick={onCloseModal}
            >
              <ClearIcon fontSize="medium" />
            </IconButton>
          </div>
          {travels.map((travel) => {
            const startAddress = travel.locations[0].address;
            const endAddress =
              travel.locations[travel.locations.length - 1].address;
            const { street, province, locality, streetNumber } = startAddress;
            const {
              street: streetEnd,
              province: provinceEnd,
              locality: localityEnd,
              streetNumber: streetNumberEnd
            } = endAddress;
            const infoRejected = travel?.states[travel?.states?.length - 2];

            return (
              <div className="mb-3 flex flex-col gap-3 px-3 py-3 rounded-lg bg-gray-100">
                <div className="flex justify-between">
                  <p className="text-lg text-gray-800 px-3 border-[1px] rounded-lg bg-white">
                    #{travel.id}
                  </p>
                  <p className="text-sm text-gray-500">
                    {moment(travel.reservationDatetime).format('DD/M/YY')}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-sm text-gray-500">
                    <span className="text-black">Origen: </span> {street}{' '}
                    {streetNumber === 0 ? '' : streetNumber}, {locality},{' '}
                    {province}
                  </p>
                  <p className="text-sm text-gray-500">
                    Llegada a origen:{' '}
                    {moment(travel.arrivalDatetime).format('HH:mm')}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-sm text-gray-500">
                    <span className="text-black">Destino: </span> {streetEnd}{' '}
                    {streetNumberEnd === 0 ? '' : streetNumberEnd},{' '}
                    {localityEnd}, {provinceEnd}
                  </p>
                  <p className="text-sm text-gray-500">
                    Llegada a destino:{' '}
                    {moment(travel.departureDateTime).format('HH:mm')}
                  </p>
                </div>
                <Divider />
                <div>
                  <div className="max-w-lg mx-auto border px-6 py-4 rounded-lg">
                    <div className="flex items-center mb-6 gap-2">
                      <Avatar>{infoRejected?.user.name.charAt(0)}</Avatar>
                      <div>
                        <div className="text-lg font-medium text-gray-800">
                          {infoRejected?.user.name}
                        </div>
                        <div className="text-gray-400 flex gap-4">
                          <p>
                            {moment(infoRejected.user.datetime).format('HH:mm')}
                          </p>
                          <p>
                            {moment(infoRejected.user.datetime).format('D/M')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="text-lg leading-relaxed mb-6">
                      {infoRejected?.observation.length
                        ? infoRejected.observation
                        : 'Sin motivo de cancelación.'}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="flex items-center justify-end gap-3 pt-4">
            <Button onClick={onCloseModal} variant="light" color="gray">
              Salir
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DriversRequestsRejectTravelMenu;
