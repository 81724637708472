import { PaginatedResponse } from '@vadiun/react-datatable';
import { ClientType } from 'app/client/main/models/ClientType';
import {
  viajeTrasladoBuilderLegacyDontUse,
  ViajeTrasladoCreateModelLegacyDontUse,
  ViajeTrasladoModelLegacyDontUse
} from 'app/viaje/models';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { LaravelPaginated } from 'shared/hooks/useCustomTablePagination';
import {
  httpClient,
  httpClientWithHeaders
} from 'shared/services/http/httpClient';
import { URLSearchParams } from 'url';
import {
  estimationClientBusinessBuilder,
  estimationClientPrivateBuilder,
  TravelEstimationClientBusinessParams,
  TravelEstimationClientPrivateParams
} from '../../../models/TravelEstimation';
import {
  transferReservationOpenedBuilder,
  TransferReservationOpenedModel
} from '../../abierto/models/TransferReservationOpened';
import {
  transferReservationManagmentBuilder,
  TransferReservationManagmentModel
} from '../../gestion/models/TransferReservationManagment';
import {
  transferReservationPendingBuilder,
  TransferReservationPendingModel
} from '../../pendiente/models/TransferReservationPending';
import { CorrectClientBusinessFormType } from '../../pendienteLiquidacion/components/CorrectClientBusinessFormik';
import { TransferReservationToValidate } from '../../pendienteValidacion/models/TransferReservationPendingValidation';
import {
  transferReservationPrivateBuilder,
  TransferReservationPrivateModel
} from '../../private/models/TransferReservatinPrivateModel';
import {
  ReservaTrasladoRepetitiveCreateModel,
  transferReservationRepetitiveBuilder,
  TransferReservationRepetitiveModel
} from '../../repetitive/models/TransferreservationRepetitiveModel';
import { RepetitiveReservationFiltersFormType } from '../components/RepetitiveReservationFilters';
import { TransferReservationBaseFiltersFormType } from '../components/ViajeTrasladoBaseFilters';
import {
  transferReservationBuilder,
  TransferReservationModel
} from '../models/TransferReservation';
import {
  TransferReservationALiquidar,
  transferReservationALiquidarBuilder
} from '../models/TransferReservationALiquidar';
import {
  TransferReservationPrices,
  TransferReservationPricesRateType
} from '../models/TransferReservationPrices';
import { ViajeTrasladoExpenses } from '../models/ViajeTrasladoExpenses';
import { ViajeTrasladoLog } from '../models/ViajeTrasladoLog';
import { ViajeTrasladoRoute } from '../models/ViajeTrasladoRoute';
import environment from 'environment/environment';
import {
  ReserveDraft,
  ReservesDrafts
} from '../../borradores/types/TravelDraft';

interface Price {
  id: number;
  waiting_minutes: number;
  km: number;
  expenses_price: number;
  transfer_price: number;
  observation: string | null;
  rate_type: TransferReservationPricesRateType;
}

export const mapBaseFilters = (
  filters: TransferReservationBaseFiltersFormType
) => {
  const dateTimeFromWithTz = filters.dateFrom.toISOString(true);
  const dateTimeToWithTz = filters.dateTo.toISOString(true);
  const dateTimeFromSplited = dateTimeFromWithTz.split(/[+-]/);
  const dateTimeFrom = [
    `${dateTimeFromSplited[0]}-${dateTimeFromSplited[1]}-${dateTimeFromSplited[2]}`,
    ...dateTimeFromSplited.slice(3, -1)
  ].join('');

  const dateTimeToSplited = dateTimeToWithTz.split(/[+-]/);
  const dateTimeTo = [
    `${dateTimeToSplited[0]}-${dateTimeToSplited[1]}-${dateTimeToSplited[2]}`,
    ...dateTimeToSplited.slice(3, -1)
  ].join('');

  return {
    date_time_from: encodeURIComponent(dateTimeFrom + 'Z'),
    date_time_to: encodeURIComponent(dateTimeTo + 'Z'),
    client_id: filters.clientId,
    service_type: filters.serviceType === 'Todos' ? null : filters.serviceType,
    has_priority: filters.hasPriority,
    passenger_name: filters.passangerName,
    street: filters.street,
    locality: filters.locality,
    passenger_phone: filters.phone,
    phone: filters.client_phone,
    reservation_id: filters.reservationId,
    client_type: filters.client_type,
    state: filters.state === 'Todos' ? null : filters.state,
    assigned_driver_id: filters.assigned_driver_id,
    pre_assigned_driver_id: filters.pre_assigned_driver_id,
    driver_code: filters.driverCode,
    oficial_cuenta: filters.oficial_cuenta_id,
    authorized_id: filters.authorized_id,
    cost_center: filters.cost_center,
    sub_cost_center: filters.sub_cost_center,
    buy_order: filters.buy_order,
    sector: filters.sector,
    driver_with_close_shift: filters.driver_with_close_shift,
    departure_time_exceeded: filters.departure_time_exceeded,
    plataforma_id: filters.plataformaId,
    ...(filters.page_size && { page_size: filters.page_size }),
    ...(filters.page && { page: filters.page }),
    ...(filters.business_unit && { business_units: filters.business_unit }),
    ...(filters.sent_reservations_platform && { sent_platform: '1' }),
    ...(filters.not_sent_reservations_platform && { sent_platform: '0' }),
    ...(filters.has_platform_id && { has_platform_id: '1' }),
    ...(filters.is_event && { is_event: true }),
    ...(filters.event_name && { event_name: filters.event_name }),
    ...(filters.is_llt_pais && { is_llt_pais: true })
  };
};

const mapRepetitiveFilters = (
  filters: RepetitiveReservationFiltersFormType
) => ({
  client_id: filters?.client_id,
  service_type: filters.service_type,
  entry_channel: filters?.entry_channel,
  has_priority: filters.hasPriority,
  passenger_name: filters.passangerName,
  street: filters.street,
  passenger_phone: filters.phone,
  id: filters.reservationId,
  authorized_id: filters.authorized_id,
  cost_center: filters.cost_center,
  buy_order: filters.buy_order,
  sector: filters.sector,
  platform_id: filters.platform_id
});

const useViajesTraslado = () => {
  const addViajesTraslado = async (
    x: ViajeTrasladoCreateModelLegacyDontUse[],
    clientBusinessID: number
  ) => {
    return httpClient.post(
      `business-clients/${clientBusinessID}/transfer-reservations/batch`,
      {
        reservations: x.map(viajeTrasladoBuilderLegacyDontUse.toBackEnd)
      }
    );
  };

  const notificarDemora = (id: number) => {
    return httpClient.post(`transfer-reservations/${id}/notificar-demora`);
  };

  const addViajeTraslado = async (
    x: ViajeTrasladoCreateModelLegacyDontUse,
    clientBusinessID: number
  ) => {
    return httpClient.post(
      `business-clients/${clientBusinessID}/transfer-reservations/batch`,
      { reservations: [viajeTrasladoBuilderLegacyDontUse.toBackEnd(x)] }
    );
  };

  const estimateClientBusinessPrice = (
    estimation: TravelEstimationClientBusinessParams,
    clientBusinessID: number
  ): Promise<{ price: number }> => {
    return httpClient.post<{ price: number }>(
      `business-clients/${clientBusinessID}/transfer-reservations/estimate`,
      estimationClientBusinessBuilder.toBackEnd(estimation)
    );
  };

  const estimateClientPrivatePrice = (
    estimation: TravelEstimationClientPrivateParams,
    clientID: number
  ): Promise<{ price: number }> => {
    return httpClient.post<{ price: number }>(
      `private-clients/${clientID}/transfer-reservations/estimate`,
      estimationClientPrivateBuilder.toBackEnd(estimation)
    );
  };

  const editViajeTraslado = async (
    x: ViajeTrasladoCreateModelLegacyDontUse,
    id: number
  ) => {
    return httpClient.put(`/transfer-reservations/${id}`, {
      ...viajeTrasladoBuilderLegacyDontUse.toBackEnd(x),
      client_type: 'Business Client',
      client_id: x.cliente.id
    });
  };

  const approveTransferReservationToValidate = async (
    x: ViajeTrasladoCreateModelLegacyDontUse,
    id: number
  ) => {
    return httpClient.put(`transfer-reservations/${id}/approve`, {
      ...viajeTrasladoBuilderLegacyDontUse.toBackEnd(x),
      client_type: 'Business Client',
      client_id: x.cliente.id
    });
  };

  const addViajeTrasladoRepetitivo = async (
    x: ReservaTrasladoRepetitiveCreateModel
  ) => {
    return httpClient.post(
      `/repetitive-transfer-reservations`,
      transferReservationRepetitiveBuilder.toBackEnd(x)
    );
  };

  const editViajeTrasladoRepetitivo = async (
    x: ReservaTrasladoRepetitiveCreateModel & { id: number }
  ) => {
    return httpClient.put(
      `/repetitive-transfer-reservations/${x.id}`,
      transferReservationRepetitiveBuilder.toBackEnd(x)
    );
  };

  const forceEnd = async (id: number, deviation_observation: string) => {
    return httpClient.put(
      `transfer-reservations/${id}/forzar-finalizacion-viaje`,
      {
        deviation_observation
      }
    );
  };

  const approveFinalPrice = async (reservation: TransferReservationModel) =>
    httpClient.post(`transfer-reservations/${reservation.id}/approve-price`);

  const approveFinalPriceContado = async (
    reservation: TransferReservationModel
  ) =>
    httpClient.post(
      `transfer-reservations/${reservation.id}/approve-price-contado`
    );

  const storeSettlement = async (
    reservations: TransferReservationALiquidar[]
  ): Promise<AxiosResponse> => {
    const data = { reservations_id: reservations.map((r) => r.id) };
    return httpClientWithHeaders.post(
      `/business-clients/${reservations[0].client.id}/transfer-settlements`,
      data,
      {
        responseType: 'blob'
      }
    );
  };

  const openToPending = async (id: number) => {
    return httpClient.put(
      `/transfer-reservations/${id}/revert-from-open-to-pending`
    );
  };

  const correctFinalPriceAuditoria = async (price: {
    id: number;
    waiting_minutes: number;
    km: number;
    expenses_price: number;
    observation: string | null;
    rate_type: TransferReservationPricesRateType;
  }) => {
    return httpClient.put(
      `/transfer-reservations/${price.id}/set-final-price`,
      price
    );
  };

  const correctFinalPriceAuditoriaContado = async (price: {
    id: number;
    waiting_minutes: number;
    km: number;
    expenses_price: number;
    observation: string | null;
    rate_type: TransferReservationPricesRateType;
  }) => {
    return httpClient.put(
      `/transfer-reservations/${price.id}/set-final-price-contado`,
      price
    );
  };

  const correctFinalPriceCuentaCorriente = async (price: {
    id: number;
    waiting_minutes: number;
    km: number;
    expenses_price: number;
    observation: string | null;
  }) => {
    return httpClient.put(
      `/transfer-reservations/${price.id}/corregir-precio-final`,
      price
    );
  };

  const correctFinalPriceContado = async (price: {
    id: number;
    waiting_minutes: number;
    km: number;
    expenses_price: number;
    observation: string | null;
  }) => {
    return httpClient.put(
      `/transfer-reservations/${price.id}/corregir-precio-final-contado`,
      price
    );
  };

  function getReservationPricing(price: {
    id: number;
    waiting_minutes: number;
    km: number;
    expenses_price: number;
    rate_type: TransferReservationPricesRateType;
  }): Promise<TransferReservationPrices> {
    return httpClient.get(
      `transfer-reservations/${price.id}/corrected-price?kilometers=${price.km}&waiting_minutes=${price.waiting_minutes}&expenses=${price.expenses_price}&rate_type=${price.rate_type}`
    );
  }

  const getViajeTrasladoRepetitivo = async (
    params: URLSearchParams,
    filters?: RepetitiveReservationFiltersFormType
  ) => {
    const mappedFilters = !filters
      ? {}
      : {
          ...mapRepetitiveFilters(filters)
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `filter[${key}]=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `repetitive-transfer-reservations?${params}&${filtersString}`
    );

    return {
      total: res.meta.total,
      data: res.data.map(transferReservationRepetitiveBuilder.fromBackEnd)
    } as PaginatedResponse<TransferReservationRepetitiveModel>;
  };

  async function getAllTransferReservationToValidate(
    params: string
  ): Promise<LaravelPaginated<TransferReservationToValidate>> {
    const res = await httpClient.get(
      `/transfer-reservations/pending-confirmation?${params}`
    );
    return {
      ...res,
      data: res.data.map(transferReservationBuilder.fromBackEnd)
    };
  }

  async function getAmountOfTransferReservationToValidate(): Promise<any> {
    const res = await httpClient.get(
      `/transfer-reservations/pending-confirmation`
    );
    return { amount: res.meta.total };
  }

  async function getTransferReservationToValidate(
    id: number | string
  ): Promise<TransferReservationToValidate> {
    const res = await httpClient.get(
      `/transfer-reservations/pending-confirmation?reservation_id=${id}`
    );
    return res.data.map(transferReservationBuilder.fromBackEnd)[0];
  }

  const denyValidationTransferReservation = (values: {
    observation: string;
    id: number;
  }) =>
    httpClient.put(`transfer-reservations/${values.id}/cancel`, {
      cancel_observation: values.observation
    });

  const getPendingAssigment = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    let filtersString = '';
    if (filters) {
      const mappedFilters = { ...mapBaseFilters(filters) };
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `transfer-reservations/pending-assignment?${params}&${filtersString}`,
      {
        headers: {
          cache: 'no-store'
        }
      }
    );
    return {
      total: res.meta.total,
      data: (res.data as any[]).map(
        transferReservationPendingBuilder.fromBackEnd
      )
    } as PaginatedResponse<TransferReservationPendingModel>;
  };

  const getDraftsReservations = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    // let filtersString = '';
    // if (filters) {
    //   const mappedFilters = { ...mapBaseFilters(filters) };
    //   filtersString = Object.entries(mappedFilters)
    //     .filter(([_, value]) => !!value)
    //     .map(([key, value]) => `${key}=${value}`)
    //     .join('&');
    // }
    const responseApi = await fetch(
      `${environment.booking}/reserves-drafts?${params}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    const res: ReservesDrafts = await responseApi.json();
    return {
      total: res.count,
      data: res.data
    } as PaginatedResponse<ReserveDraft>;
  };

  const cancelReserveDraft = async (idBooking: number, reason: string) => {
    const responseApi = await fetch(
      `${environment.booking}/reserves-drafts/${idBooking}/cancel`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reason })
      }
    );
    const res: ReservesDrafts = await responseApi.json();
    return res;
  };

  const getAllOpen = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    const mappedFilters = !filters
      ? {}
      : {
          ...mapBaseFilters(filters)
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `transfer-reservations/opened?${params}&${filtersString}`
    );
    const data = (res.data as any[]).map(
      transferReservationOpenedBuilder.fromBackEnd
    );
    const sorted = data.sort((a, b) => {
      if (a.departureDatetime.isBefore(moment())) return -1;
      return b.departureDatetime.diff(a.departureDatetime);
    });
    return {
      total: res.meta.total,
      data: sorted
    } as PaginatedResponse<TransferReservationOpenedModel>;
  };

  const getPendingAudit = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    const mappedFilters = !filters
      ? {}
      : {
          ...mapBaseFilters(filters),
          client_type: 'BusinessClient'
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `transfer-reservations/pending-audit?${params}&${filtersString}`
    );
    return {
      total: res.meta.total,
      data: res.data.map(transferReservationBuilder.fromBackEnd)
    } as PaginatedResponse<TransferReservationModel>;
  };

  const getPendingAuditContado = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    const mappedFilters = !filters
      ? {}
      : {
          ...mapBaseFilters(filters),
          client_type: 'BusinessClient'
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `transfer-reservations/pending-audit-contado?${params}&${filtersString}`
    );
    return {
      total: res.meta.total,
      data: res.data.map(transferReservationBuilder.fromBackEnd)
    } as PaginatedResponse<TransferReservationModel>;
  };

  const getACorregirCuentaCorriente = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    const mappedFilters = !filters
      ? {}
      : {
          ...mapBaseFilters(filters),
          client_type: 'BusinessClient'
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `transfer-reservations/disponible-para-corregir?${params}&${filtersString}`
    );
    return {
      total: res.meta.total,
      data: res.data.map(transferReservationBuilder.fromBackEnd)
    } as PaginatedResponse<TransferReservationModel>;
  };

  const getACorregirContado = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    const mappedFilters = !filters
      ? {}
      : {
          ...mapBaseFilters(filters),
          client_type: 'BusinessClient'
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `transfer-reservations/disponible-para-corregir-contado?${params}&${filtersString}`
    );
    return {
      total: res.meta.total,
      data: res.data.map(transferReservationBuilder.fromBackEnd)
    } as PaginatedResponse<TransferReservationModel>;
  };

  /*   const getAvailableToCorrect = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    const mappedFilters = !filters
      ? {}
      : {
          ...mapBaseFilters(filters),
          client_type: 'BusinessClient'
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `transfer-reservations/pending-audit?${params}&${filtersString}`
    );
    return {
      total: res.meta.total,
      data: res.data.map(transferReservationBuilder.fromBackEnd)
    } as PaginatedResponse<TransferReservationModel>;
  }; */

  const getPendingSettlement = async (
    filters?: TransferReservationBaseFiltersFormType
  ): Promise<{
    data: TransferReservationALiquidar[];
    totalResults: number;
  }> => {
    const mappedFilters = !filters
      ? {}
      : {
          ...mapBaseFilters(filters)
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get<{ data: any[] }>(
      `transfer-reservations/pending-settlement?${filtersString}`
    );

    return {
      data: res.data?.map(transferReservationALiquidarBuilder.fromBackEnd),
      totalResults: res.meta.total || 0
    };
  };

  const getAll = async (
    params: URLSearchParams,
    filters?: TransferReservationBaseFiltersFormType
  ) => {
    let filtersString = '';
    if (filters) {
      const mappedFilters = {
        ...mapBaseFilters(filters)
      };
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    const res = await httpClient.get(
      `transfer-reservations?${params}&${filtersString}`
    );
    return {
      total: res.meta.total,
      data: res.data.map(transferReservationManagmentBuilder.fromBackEnd)
    } as PaginatedResponse<TransferReservationManagmentModel>;
  };

  async function getReservationbySettlement(
    settlementId: string,
    params: URLSearchParams
  ) {
    const res = await httpClient.get(
      `transfer-reservations?settlement_id=${settlementId}&${params}`
    );
    return {
      total: res.meta.total,
      data: res.data.map(transferReservationManagmentBuilder.fromBackEnd)
    } as PaginatedResponse<TransferReservationManagmentModel>;
  }

  const getExportData = async (filters?: any): Promise<any> => {
    let filtersString = '';
    if (filters) {
      const mappedFilters = {
        ...mapBaseFilters(filters)
      };
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }

    const responseApi = await fetch(
      `${environment.export}/transfer-reservations/export?${filtersString}`,
      {
        method: 'POST',
        body: JSON.stringify({
          columns: [
            'id',
            'state',
            'client_name',
            'payment_type',
            'date',
            'hour',
            'first_address',
            'last_address',
            'kilometers',
            'km_price',
            'waiting_minutes',
            'waiting_minutes_price',
            'transfer_price',
            'expenses_price',
            'applicant_name',
            'passengers_names',
            'driver_name',
            'deviation_observation',
            'coordination_observation',
            'service_type',
            'entry_channel',
            'platform_application',
            'observation_billed',
            'unit_km_price',
            'accepted_datetime',
            'fecha_creacion',
            'demora_notificada',
            'is_event',
            'is_llt_pais',
            'provider_price',
            'customer_price',
            'created_by',
            'cancelled_by',
            'cancellation_date',
            'cancellation_reason',
            'last_changed_by',
            'last_change_date',
            'last_change_reason',
            'business_unit_name',
            'accepted_datetime',
            'acceptance_time',
            'announcement_date',
            'announcement_time',
            'trip_start_time',
            'assigned_by_operator',
            'time_creation',
            'trip_start_date',
            'audit_reason',
            'vehicle_id',
            'vehicle_patent',
            'vehicle_year',
            'vehicle_model_name',
            'chassis_number',
            'brand_name',
            'requirement_id',
            'cost_center',
            'event_name',
            'sub_cost_center',
            'sector',
            'buy_order',
            'employee_number',
            'trip_number',
            'data_1',
            'data_2',
            'data_3',
            'authorized_id',
            'authorized_name',
            'authorized_phone',
            'authorized_email',
            'authorized_clientable_id',
            'authorized_clientable_type',
            'applicant_id',
            'applicant_phone',
            'applicant_email',
            'applicant_clientable_id',
            'applicant_clientable_type',
            'observation_lltpais',
            'observation_last_change_audit'
          ]
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          accept: 'application/octet-stream'
        }
      }
    );

    const result = await responseApi.json();
    return result;
  };

  const preAssignDriver = (reservationId: number, driverId: number) =>
    httpClient.put(`transfer-reservations/${reservationId}/pre-assign-driver`, {
      driver_id: driverId
    });

  const removePreAssignedDriver = (reservationId: number) =>
    httpClient.delete(
      `transfer-reservations/${reservationId}/remove_pre_assigned_driver`
    );

  const assignDriver = async (reservationId: number, driverId: number) => {
    await httpClient.put(
      `transfer-reservations/${reservationId}/assign-driver`,
      {
        driver_id: driverId
      }
    );
  };

  const reAssignDriver = async (reservationId: number, driverId: number) => {
    await httpClient.put(
      `transfer-reservations/${reservationId}/re-assign-driver`,
      {
        driver_id: driverId
      }
    );
  };

  const cancelReservation = (reservationId: number, observation: string) =>
    httpClient.put(`transfer-reservations/${reservationId}/cancel`, {
      cancel_observation: observation
    });

  const getRoute = (travelId: number): Promise<ViajeTrasladoRoute[]> =>
    httpClient.get(`transfer-reservations/${travelId}/geolocations`);

  const getLegacyDontUse = async (
    travelId: number
  ): Promise<ViajeTrasladoModelLegacyDontUse> => {
    const res = await httpClient.get(`transfer-reservations/${travelId}`);
    return viajeTrasladoBuilderLegacyDontUse.fromBackEnd(res);
  };

  const get = async (
    travelId: number
  ): Promise<TransferReservationModel | TransferReservationPrivateModel> => {
    const res = await httpClient.get(`transfer-reservations/${travelId}`);
    if (res.clientType === ClientType.business) {
      return transferReservationBuilder.fromBackEnd(res);
    } else {
      return transferReservationPrivateBuilder.fromBackEnd(res);
    }
  };

  const getExpenses = async (
    reservationId: number
  ): Promise<ViajeTrasladoExpenses[]> =>
    httpClient.get(`transfer-reservations/${reservationId}/expenses`);

  const deleteReservation = async (reservationId: number) =>
    httpClient.delete(`transfer-reservations/${reservationId}`);

  const deleteRepetitiveReservation = async (reservationId: number) =>
    httpClient.delete(`repetitive-transfer-reservations/${reservationId}`);

  const correctClient = (
    data: CorrectClientBusinessFormType & { id: number }
  ) => {
    return httpClient.put(
      `/transfer-reservations/${data.id}/recalculate-prices`,
      {
        client_id: data.client?.id,
        has_out_site: data.hasOutSite,
        platform_id: data.platformApplication,
        requirement: {
          applicant_name: data.solicitante.name,
          applicant_email: data.solicitante.email,
          applicant_phone: data.solicitante.phone,
          cost_center: data.costCenter,
          sub_cost_center: data.subCostCenter,
          sector: data.sector,
          authorized_id: data.autorizado?.id,
          buy_order: data.buyOrder,
          employee_number: data.employeeNumber,
          platform_application: data.platformApplication,
          trip_number: data.tripNumber,
          data_1: data.data1,
          data_2: data.data2,
          data_3: data.data3
        }
      }
    );
  };

  const getModificaciones = async (id: number) => {
    const res = await httpClient.get<any[]>(
      `/transfer-reservations/${id}/logs`
    );
    return res.map((x) => ({
      ...x,
      fecha_hora: moment(x.fecha_hora)
    })) as ViajeTrasladoLog[];
  };

  const reservationsToPlatform = async (reservationsId: number[]) =>
    await httpClient.put('/transfer-reservations-to-platform', {
      reservations_ids: reservationsId,
      sent_platform: true
    });

  return {
    addViajeTraslado,
    editViajeTraslado,
    getLegacyDontUse,
    addViajesTraslado,
    estimateClientBusinessPrice,
    getPendingAssigment,
    preAssignDriver,
    removePreAssignedDriver,
    assignDriver,
    reAssignDriver,
    getRoute,
    getAllOpen,
    getAll,
    cancelReservation,
    getExpenses,
    addViajeTrasladoRepetitivo,
    getViajeTrasladoRepetitivo,
    editViajeTrasladoRepetitivo,
    getPendingAudit,
    getPendingSettlement,
    forceEnd,
    openToPending,
    approveFinalPrice,
    correctFinalPriceAuditoria,
    correctFinalPriceCuentaCorriente,
    storeSettlement,
    get,
    getExportData,
    deleteReservation,
    deleteRepetitiveReservation,
    correctClient,
    getReservationbySettlement,
    getReservationPricing,
    estimateClientPrivatePrice,
    getAllTransferReservationToValidate,
    denyValidationTransferReservation,
    getTransferReservationToValidate,
    approveTransferReservationToValidate,
    getAmountOfTransferReservationToValidate,
    getPendingAuditContado,
    getACorregirCuentaCorriente,
    getACorregirContado,
    correctFinalPriceContado,
    correctFinalPriceAuditoriaContado,
    approveFinalPriceContado,
    getModificaciones,
    notificarDemora,
    reservationsToPlatform,
    getDraftsReservations,
    cancelReserveDraft
  };
};

export default useViajesTraslado;
