import { ApplicantCrud } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import { AuthorizedModelCRUD } from 'app/client/clientBusiness/Authorized/models/Authorized';
import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import {
  PasajeroTrasladoModel,
  ViajeCanalesDeIngreso,
  ViajeTrasladoCreateModelLegacyDontUse,
  ViajeTrasladoModelLegacyDontUse,
  ViajeTrasladoPunto,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';

export interface TransferReservationBaseFormType {
  cliente: ClientBusinessModel | null;
  solicitante: ApplicantCrud;
  autorizado: AuthorizedModelCRUD | null;
  costCenter: string;
  subCostCenter: string;
  sector: string;
  buyOrder: string;
  employeeNumber: string;
  platformApplication: string;
  tripNumber: string;
  canalIngreso: ViajeCanalesDeIngreso;
  plataformaId: string | null;
  prioridad: boolean;
  tipoServicio: ViajeTrasladoTipoServicio;
  observacionCoordinacion: string | null;
  fixedRate: RateModel['categories'][0]['fixedPrices'][0] | null;
  hasOutSite: boolean;
  data1: string;
  data2: string;
  data3: string;
  pay_with_cash: boolean;
  isEvent: boolean;
  nameEvent?: string;
  isLLTPais: boolean;
  providerPrice?: number;
  customerPrice?: number;
}

export interface TransferReservationBaseLocationFormType {
  domicilio: DomicilioModel;
  pasajeros: (Omit<PasajeroTrasladoModel, 'telefono'> & {
    telefono: string | number;
  })[];
  observacion: string | null;
  espera: string | null;
}

function transferReservationBaseLocationFormToModel(
  form: TransferReservationBaseLocationFormType
): ViajeTrasladoPunto {
  return {
    domicilio: form.domicilio,
    pasajeros: form.pasajeros.map((p) => ({
      ...p,
      telefono: Number(p.telefono)
    })),
    observacion: form.observacion ?? '',
    minutosEspera: Number(form.espera)
  };
}

function transferReservationBaseLocationModelToForm(
  p: ViajeTrasladoPunto
): TransferReservationBaseLocationFormType {
  return {
    domicilio: p.domicilio,
    espera: String(p.minutosEspera),
    observacion: p.observacion,
    pasajeros: p.pasajeros
  };
}

export const transferReservationBaseLocationFormBuilder = {
  toModel: transferReservationBaseLocationFormToModel,
  toForm: transferReservationBaseLocationModelToForm
};

export function transferReservationBaseFormToModel(
  form: TransferReservationBaseFormType
): Omit<
  ViajeTrasladoCreateModelLegacyDontUse,
  'fechaYHoraDeLlegada' | 'fechaYHoraDeSalida' | 'puntos'
> {
  return {
    cliente: {
      id: form.cliente!.id,
      name: form.cliente!.fantasyName
    },
    canalIngreso: form.canalIngreso,
    plataformaId: form.plataformaId,
    prioridad: form.prioridad,
    solicitante: form.solicitante,
    tipoServicio: form.tipoServicio,
    autorizado: form.autorizado === null ? undefined : form.autorizado,
    observacionCoordinacion: form.observacionCoordinacion,
    fixedRate: form.fixedRate!,
    costCenter: form.costCenter,
    subCostCenter: form.subCostCenter,
    sector: form.sector,
    buyOrder: form.buyOrder,
    employeeNumber: form.employeeNumber,
    platformApplication: form.platformApplication,
    tripNumber: form.tripNumber,
    hasOutSite: form.hasOutSite,
    data1: form.data1,
    data2: form.data2,
    data3: form.data3,
    pay_with_cash: form.pay_with_cash,
    is_event: form.isEvent,
    event_name: form.nameEvent,
    lltpais: form.isLLTPais,
    provider_price: form.providerPrice,
    customer_price: form.customerPrice
  };
}

export function transferReservationModelToBaseForm(
  reserva: ViajeTrasladoModelLegacyDontUse,
  cliente: ClientBusinessModel,
  fixedRate: RateModel['categories'][0]['fixedPrices'][0] | null
): TransferReservationBaseFormType {
  return {
    cliente,
    solicitante: reserva.solicitante,
    autorizado: reserva.autorizado || null,
    costCenter: reserva.costCenter || '',
    subCostCenter: reserva.subCostCenter || '',
    sector: reserva.sector || '',
    buyOrder: reserva.buyOrder || '',
    employeeNumber: reserva.employeeNumber || '',
    platformApplication: reserva.platformApplication || '',
    tripNumber: reserva.tripNumber || '',
    canalIngreso: reserva.canalIngreso || '',
    plataformaId: reserva.plataformaId || '',
    prioridad: reserva.prioridad,
    tipoServicio: reserva.tipoServicio,
    observacionCoordinacion: reserva.observacionCoordinacion || '',
    fixedRate,
    hasOutSite: reserva.hasOutSite,
    data1: reserva.data1,
    data2: reserva.data2,
    data3: reserva.data3,
    pay_with_cash: reserva.pay_with_cash,
    isEvent: reserva.is_event,
    nameEvent: reserva.event_name,
    isLLTPais: reserva.lltpais,
    providerPrice: reserva.provider_price,
    customerPrice: reserva.customer_price
  };
}

export const transferReservationBaseFormBuilder = {
  toModel: transferReservationBaseFormToModel,
  toForm: transferReservationModelToBaseForm
};
