import { useSnackbar } from 'notistack';
import { useState } from 'react';
import environment from '../../../../../environment/environment';

export const useLLTPais = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const onEditTravel = async (idTravel: number, data: any) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${environment.backEnd}transfer-reservations/${idTravel}/change-driver-lltpais`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(data)
        }
      );

      if (!response.ok) {
        throw new Error('Error al actualizar el viaje');
      }

      setLoading(false);
      snackbar.enqueueSnackbar(`Se actualizo el viaje: #${idTravel}`, {
        variant: 'success'
      });
    } catch (error: any) {
      snackbar.enqueueSnackbar(error.message, {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    onEditTravel
  };
};
