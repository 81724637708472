import { useState } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';

type Props = {
  onRefreshTable: () => Promise<void>;
};

const RefreshList = ({ onRefreshTable }: Props) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  return (
    <IconButton
      title="Refrescar tabla"
      onClick={async () => {
        setIsRefreshing(true);
        await onRefreshTable();
        setIsRefreshing(false);
      }}
    >
      {isRefreshing ? (
        <CircularProgress
          size={19}
          style={{
            color: 'gray',
            marginTop: 2
          }}
          thickness={4}
        />
      ) : (
        <Refresh />
      )}
    </IconButton>
  );
};

export default RefreshList;
